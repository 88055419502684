import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

export class DialogHelper {

  static formatDate(dateString: string): string {
    let date = new Date(dateString);
    let year = date.getUTCFullYear();
    let month = String(date.getUTCMonth() + 1).padStart(2, '0');
    let day = String(date.getUTCDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  static getErrorMessage(control: AbstractControl, translate: TranslateService): string {
    if (control.hasError('invalidNumber')) {
      return translate.instant('common.validators.invalidNumber'); 
    } else if (control.hasError('minlength')) {
      return translate.instant('common.validators.theFieldMustContainAtLeast1', { count: control.errors?.['minlength'].requiredLength });
    } else if (control.hasError('maxlength')) {
      return translate.instant('common.validators.theFieldCanContainAMaximumOf1', { count: control.errors?.['maxlength'].requiredLength });
    } else if (control.hasError('min')) {
      return translate.instant('common.validators.theFieldCannotBeNegative');
    } else if (control.hasError('max')) {
      return translate.instant('common.validators.theFieldCannotExceed1', { max: control.errors?.['max'].max });
    } else if (control.hasError('invalidNIP')) {
      return translate.instant('common.validators.invalidNIP'); 
    }else if (control.hasError('invalidVAT')) {  
      return translate.instant('common.validators.invalidVAT'); 
    }else if (control.hasError('required')) {
      return translate.instant('common.validators.theFieldCannotBeEmpty');
    }
    return '';
  }


  isRequired(inputName: string, form: FormGroup): boolean | null{
    const control = form.get(inputName);
  
    if (control && control.validator) {
      const validator = control.validator({} as AbstractControl);
      return validator && validator.hasOwnProperty('required');
    }
  
    return false;
  }

}
